import { WalletAddress } from "@libs/shares/models/wallet.model";
import { SHA3 } from "sha3";

export const encrypt = (id: string, entryptSalt = "1InchDevPortal"): string => {
  const hash = new SHA3(256);
  return hash.update(id + entryptSalt).digest("hex");
};

export const shortenWalletAddress = (address: WalletAddress): `0x${string}...${string}` => {
  return `0x${address.slice(2, 6)}...${address.slice(-4)}`;
};
